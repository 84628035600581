// app/_components/DndProviderWrapper.tsx
'use client'

import { useEffect } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

import { DndContextProvider } from './DndContext'
import { DragDropErrorBoundary } from './DragDropErrorBoundary'

// app/_components/DndProviderWrapper.tsx

// app/_components/DndProviderWrapper.tsx

// app/_components/DndProviderWrapper.tsx

// Add type declaration for the cleanup function
declare global {
  interface Window {
    __REACT_DND_STORE_CLEANUP__?: () => void
  }
}

// app/_components/DndProviderWrapper.tsx

// app/_components/DndProviderWrapper.tsx

export default function DndProviderWrapper({ children }: { children: React.ReactNode }) {
  useEffect(() => {
    // Log mount in development
    // if (process.env.NODE_ENV === 'development') {
    //   console.info('DndProvider mounted')
    // }

    return () => {
      // Force cleanup of any lingering DnD connections
      window.__REACT_DND_STORE_CLEANUP__?.()

      // if (process.env.NODE_ENV === 'development') {
      //   console.info('DndProvider unmounted and cleaned up')
      // }
    }
  }, [])

  return (
    <DragDropErrorBoundary>
      <DndContextProvider>
        <DndProvider backend={HTML5Backend} debugMode={process.env.NODE_ENV === 'development'}>
          {children}
        </DndProvider>
      </DndContextProvider>
    </DragDropErrorBoundary>
  )
}
