'use client'

import { createContext, useCallback, useContext, useState } from 'react'

interface DndContextType {
  hasError: boolean
  setError: (error: boolean) => void
  resetError: () => void
  isDragging: boolean
  setIsDragging: (dragging: boolean) => void
}

const DndContext = createContext<DndContextType | undefined>(undefined)

export function DndContextProvider({ children }: { children: React.ReactNode }) {
  const [hasError, setHasError] = useState(false)
  const [isDragging, setIsDragging] = useState(false)

  const setError = useCallback((error: boolean) => {
    setHasError(error)
    // Auto-reset error after a delay
    if (error) {
      setTimeout(() => setHasError(false), 1000)
    }
  }, [])

  const resetError = useCallback(() => {
    setHasError(false)
  }, [])

  return (
    <DndContext.Provider
      value={{
        hasError,
        setError,
        resetError,
        isDragging,
        setIsDragging,
      }}>
      {children}
    </DndContext.Provider>
  )
}

export function useDndContext() {
  const context = useContext(DndContext)
  if (context === undefined) {
    throw new Error('useDndContext must be used within a DndContextProvider')
  }
  return context
}
