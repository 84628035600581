import { ApolloClient, ApolloProvider, InMemoryCache, NormalizedCacheObject } from '@apollo/client'
// links Apollo cache with chosen custom cache
import { CachePersistor } from 'apollo3-cache-persist'
import { fetchAuthSession } from 'aws-amplify/auth'
// storage library
import * as localForage from 'localforage'
import { FC, useEffect, useState } from 'react'

import { WaitingWithData } from '../../app/_components/WaitingWithData'
// utils
import { createLink } from '../utils/apolloLink-utils'
import { apolloClientContext } from '../utils/context-utils'

// DOC
// https://medium.com/@guillac124/create-your-custom-apollo-client-for-aws-appsync-to-use-hooks-2d5cbce29db5
// https://stackoverflow.com/questions/60804372/proper-way-to-setup-awsappsyncclient-apollo-react
// https://dev.to/danielbayerlein/migrate-apollo-from-v2-to-v3-in-conjunction-with-aws-appsync-16c0
// https://stackoverflow.com/questions/67368064/what-is-the-difference-betweeen-aws-sdk-clients-appsync-and-aws-appsync

// PLACE this hoc AFTER the HOC withLoginState

const withAppSync = (Component: FC) => {
  const MyComp = props => {
    const [client, setClient] = useState<ApolloClient<NormalizedCacheObject> | undefined>(undefined)
    // const [persistor, setPersistor] = useState<CachePersistor<NormalizedCacheObject>>()
    // Wait for the cache to be restored before rendering my app
    // https://github.com/apollographql/apollo-cache-persist/blob/HEAD/examples/web/src/index.tsx
    useEffect(() => {
      async function init() {
        const cache = new InMemoryCache({
          typePolicies: {
            // Need to specify keyfields and merge property for site because of following warning in console:  "Cache data may be lost when replacing the getSite field of a Query object. This could cause additional (usually avoidable) network requests to fetch data that were otherwise cached. To address this problem (which is not a bug in Apollo Client), either ensure all objects of type Site have an ID or a custom merge function, or define a custom merge function for the Query.getSite field, so InMemoryCache can safely merge these objects:"
            Site: {
              keyFields: ['id'],
              fields: {
                companyInfo: {
                  merge: true, // Keep existing data when updating
                },
              },
            },
            Collection: {
              keyFields: ['collectionId'],
            },
            Block: {
              keyFields: ['id', 'sk'],
            },
            BlockTemplate: {
              keyFields: ['id', 'sk'],
            },
            PageTemplate: {
              keyFields: ['id', 'sk'],
            },
            Page: {
              keyFields: ['id', 'sk'],
            },
            BlockPubTagHeader: {
              keyFields: false,
            },
            BlockPubAuthorOverview: {
              keyFields: false,
            },
            BlockPubSectionMainView: {
              keyFields: false,
            },
            BlockPubArticleHeader: {
              keyFields: false,
            },
            BlockPubArticleBody: {
              keyFields: false,
            },
            BlockPubSectionsTopStory: {
              keyFields: false,
            },
            BlockPubExternalReferences: {
              keyFields: false,
            },
            BlockPubSectionRelatedArticlesEmphasisAuthors: {
              keyFields: false,
            },
            BlockPubBreakingNews: {
              keyFields: false,
            },
            BlockPubAdHero: {
              keyFields: false,
            },
            BlockPubArticlesBySection: {
              keyFields: false,
            },
            BlockWysiwyg: {
              keyFields: false,
            },
            BlockPubHomeTopStories: {
              keyFields: false,
            },
            BlockPubHomeHero: {
              keyFields: false,
            },
            BlockPubLeadArticle: {
              keyFields: false,
            },
            BlockFaq: {
              keyFields: false,
            },
            BlockCoverImage: {
              keyFields: false,
            },
            BlockCards: {
              keyFields: false,
            },
            BlockPubNavSqueeze: {
              keyFields: false,
            },
            BlockPubSubscribe: {
              keyFields: false,
            },
            BlockPubSignIn: {
              keyFields: false,
            },
            BlockGoogleAd: {
              keyFields: false,
            },
            BlockPubNewsletters: {
              keyFields: false,
            },
            BlockPubSearch: {
              keyFields: false,
            },
            BlockPubAuthorRelatedArticles: {
              keyFields: false,
            },
            BlockPubSectionRelatedArticles: {
              keyFields: false,
            },
            BlockPubTagRelatedArticles: {
              keyFields: false,
            },
            BlockEmailCapture: {
              keyFields: false,
            },
            BlockPubArticleRelatedArticles: {
              keyFields: false,
            },
            BlockPubLatestArticles: {
              keyFields: false,
            },
            BlockPubSectionTopStories: {
              keyFields: false,
            },
            BlockPubMediaTopStories: {
              keyFields: false,
            },
            BlockPubAccount: {
              keyFields: false,
            },
            BlockPubGift: {
              keyFields: false,
            },
            BlockPubPromo: {
              keyFields: false,
            },
          },
          possibleTypes: {
            BlockData: [
              'BlockCards',
              'BlockCoverImage',
              'BlockFaq',
              'BlockGoogleAd',
              'BlockPubTagRelatedArticles',
              'BlockPubAuthorRelatedArticles',
              'BlockPubSectionRelatedArticles',
              'BlockPubSearch',
              'BlockPubTagHeader',
              'BlockPubAuthorOverview',
              'BlockPubSectionMainView',
              'BlockPubArticleHeader',
              'BlockPubArticleBody',
              'BlockPubSectionsTopStory',
              'BlockPubExternalReferences',
              'BlockPubSectionRelatedArticlesEmphasisAuthors',
              'BlockPubBreakingNews',
              'BlockPubAdHero',
              'BlockPubArticlesBySection',
              'BlockWysiwyg',
              'BlockPubHomeTopStories',
              'BlockPubHomeHero',
              'BlockPubLeadArticle',
              'BlockPubSubscribe',
              'BlockPubSignIn',
              'BlockPubNewsletters',
              'BlockPubLatestArticles',
              'BlockPubMediaTopStories',
              'BlockPubAccount',
              'BlockPubGift',
            ],
            BlockTemplateData: [
              'BlockPubHeaderMenu',
              'BlockPubFooter',
              'BlockPubNavSqueeze',
              'BlockPubAuthorRelatedArticles',
              'BlockPubSectionRelatedArticles',
              'BlockPubTagRelatedArticles',
              'BlockEmailCapture',
              'BlockPubArticleRelatedArticles',
              'BlockPubSectionTopStories',
              'BlockPubPromo',
            ],
          },
        })
        const newPersistor = new CachePersistor<NormalizedCacheObject>({
          cache,
          storage: localForage, // used instead of localStorage as the later has a 5MB limitation
          key: 'cache-persist', // key for the cache. please do not change
          debug: true,
          maxSize: false,
          trigger: 'write',
        })
        try {
          await newPersistor.restore()
          // setPersistor(newPersistor)
          setClient(
            new ApolloClient({
              connectToDevTools: process.env.NODE_ENV === 'development',
              link: createLink({
                type: 'AMAZON_COGNITO_USER_POOLS',
                jwtToken: async () => {
                  try {
                    const result = await fetchAuthSession()
                    return result.tokens?.idToken?.toString()
                    // return user.getIdToken().getJwtToken()
                  } catch (err) {
                    console.error('error in withAppSync jwtToken', err)
                  }
                  // Required when you use Cognito UserPools OR OpenID Connect. token object is obtained previously
                },
              }),
              cache,
            }),
          )
        } catch (error) {
          console.error('error in withAppSync restore', error)
        }
      }

      init().catch(console.error)
    }, [])

    return client === undefined ? (
      <WaitingWithData {...props} />
    ) : (
      <ApolloProvider client={client}>
        <apolloClientContext.Provider value={client}>
          <Component {...props} />
        </apolloClientContext.Provider>
      </ApolloProvider>
    )
  }
  MyComp.displayName = 'withAppSync'
  return MyComp
}

export default withAppSync
